<template>
  <div>
    <div class="d-flex">
      <div class="col-md-6">
        <b-card>
          <b-card-header>
            <b-card-title>Nombres d'employés salariés</b-card-title>
            <b-dropdown
              text="Choisir une période"
              variant="transparent"
              class="chart-dropdown "
              right
              no-caret
              toggle-class="p-0 mb-25"
              size="sm"
            >
              <b-dropdown-item
                v-for="day in chartData.lastDays"
                :key="day"
              >
                {{ day }}
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <b-card-body>
            <!-- <h3>Femmes</h3> -->
            <div class="row">
              <div class="col-md-6">
                <h3>Nationaux</h3>
                <!-- apex chart -->
                <vue-apex-charts
                  type="pie"
                  height="325"
                  class="mt-2 mb-1"
                  :options="femmeNationauxPie.chartOptions"
                  :series="femmeNationauxPie.series"
                />

                <!-- list group -->
                <div class="pt-25">
                  <div
                    v-for="(data,index) in chartData.listData"
                    :key="index"
                    class="d-flex justify-content-between"
                    :class="index === Object.keys(chartData.listData).length - 1 ? '':'mb-1'"
                  >
                    <div class="series-info">
                      <feather-icon
                        :icon="data.icon"
                        size="16"
                        class="mr-50"
                        :class="data.iconColor"
                      />
                      <span class="font-weight-bolder">{{ data.text }}</span>
                    </div>
                    <span>{{ data.result }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <h3>Etrangers</h3>
                <!-- apex chart -->
                <vue-apex-charts
                  type="pie"
                  height="325"
                  class="mt-2 mb-1"
                  :options="femmeEtrangersPie.chartOptions"
                  :series="femmeEtrangersPie.series"
                />

                <!-- list group -->
                <div class="pt-25">
                  <div
                    v-for="(data,index) in chartData.listData"
                    :key="index"
                    class="d-flex justify-content-between"
                    :class="index === Object.keys(chartData.listData).length - 1 ? '':'mb-1'"
                  >
                    <div class="series-info">
                      <feather-icon
                        :icon="data.icon"
                        size="16"
                        class="mr-50"
                        :class="data.iconColor"
                      />
                      <span class="font-weight-bolder">{{ data.text }}</span>
                    </div>
                    <span>{{ data.result }}</span>
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>

      <div class="col-md-6">
        <b-card>
          <b-card-header>
            <b-card-title>Nombres d'employés salariés</b-card-title>
            <b-dropdown
              text="Choisir une période"
              variant="transparent"
              class="chart-dropdown "
              right
              no-caret
              toggle-class="p-0 mb-25"
              size="sm"
            >
              <b-dropdown-item
                v-for="day in chartData.lastDays"
                :key="day"
              >
                {{ day }}
              </b-dropdown-item>
            </b-dropdown>
          </b-card-header>

          <b-card-body>
            <!-- <h3>Femmes</h3> -->
            <div class="row">
              <div class="col-md-6">
                <h3>Nationaux</h3>
                <!-- apex chart -->
                <vue-apex-charts
                  type="pie"
                  height="325"
                  class="mt-2 mb-1"
                  :options="hommeNationauxPie.chartOptions"
                  :series="hommeNationauxPie.series"
                />

                <!-- list group -->
                <div class="pt-25">
                  <div
                    v-for="(data,index) in chartData.listData"
                    :key="index"
                    class="d-flex justify-content-between"
                    :class="index === Object.keys(chartData.listData).length - 1 ? '':'mb-1'"
                  >
                    <div class="series-info">
                      <feather-icon
                        :icon="data.icon"
                        size="16"
                        class="mr-50"
                        :class="data.iconColor"
                      />
                      <span class="font-weight-bolder">{{ data.text }}</span>
                    </div>
                    <span>{{ data.result }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-6">
                <h3>Etrangers</h3>
                <!-- apex chart -->
                <vue-apex-charts
                  type="pie"
                  height="325"
                  class="mt-2 mb-1"
                  :options="hommeEtrangersPie.chartOptions"
                  :series="hommeEtrangersPie.series"
                />

                <!-- list group -->
                <div class="pt-25">
                  <div
                    v-for="(data,index) in chartData.listData"
                    :key="index"
                    class="d-flex justify-content-between"
                    :class="index === Object.keys(chartData.listData).length - 1 ? '':'mb-1'"
                  >
                    <div class="series-info">
                      <feather-icon
                        :icon="data.icon"
                        size="16"
                        class="mr-50"
                        :class="data.iconColor"
                      />
                      <span class="font-weight-bolder">{{ data.text }}</span>
                    </div>
                    <span>{{ data.result }}</span>
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { mapActions } from 'vuex'
import { $themeColors } from '@themeConfig'

const chartLabels = []
const chartData = {
  lastDays: ['28 Derniers jours', 'Mois dernier', 'Annee Dernière'],
  // listData: [
  //   {
  //     icon: 'CircleIcon',
  //     iconColor: 'text-primary',
  //     text: 'Agences de voyage',
  //     result: 0,
  //   },
  //   {
  //     icon: 'CircleIcon',
  //     iconColor: 'text-warning',
  //     text: 'Tour operator',
  //     result: 0,
  //   },
  //   {
  //     icon: 'CircleIcon',
  //     iconColor: 'text-danger',
  //     text: 'Agence réceptive',
  //     result: 0,
  //   },
  // ],
}

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
  },
  data() {
    return {
      // chartData: {},
      finalData: null,
      chartLabels,
      chartData,

      // Femmes Nationaux
      femmeNationauxPie: {
        series: [50, 50],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          // labels: ['Temps plein', 'Temps partiel'],
          labels: chartLabels,
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          stroke: {
            width: 4,
          },
          colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
        },
      },

      // Femmes Etrangers
      femmeEtrangersPie: {
        series: [50, 50],
        chartOptions: {
          chart: {
            toolbar: {
              show: true,
            },
          },
          // labels: ['Temps plein', 'Temps partiel'],
          labels: chartLabels,
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          stroke: {
            width: 4,
          },
          colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
        },
      },

      // Hommes Nationaux
      hommeNationauxPie: {
        series: [75, 25],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          // labels: ['Temps plein', 'Temps partiel'],
          labels: chartLabels,
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          stroke: {
            width: 4,
          },
          colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
        },
      },

      // Hommes Etrangers
      hommeEtrangersPie: {
        series: [50, 50],
        chartOptions: {
          chart: {
            toolbar: {
              show: true,
            },
          },
          // labels: ['Temps plein', 'Temps partiel'],
          labels: chartLabels,
          dataLabels: {
            enabled: true,
          },
          legend: { show: true },
          stroke: {
            width: 4,
          },
          colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
        },
      },
    }
  },

  async mounted() {
    // this.isLoading = false
    await this.getStatistics()
    // console.log('getStatistics')
  },

  methods: {
    ...mapActions('statistics', {
      action_getStatisticsExploitationEtablissements:
        'getStatisticsExploitationEtablissements',
    }),
    async getStatistics() {
      this.isLoading = true
      // const fieldKey = 'tables'
      // const elemId = 'typeEtablissement'
      // const tables = {
      const tables = [
        'formstatrestau',
        'formstathotel',
        'formclienthotel',
        'formstatagence',
        'formstatlocationvehiculesansop',
        'formstattransport',
      ]
      // }

      // console.log('Ce que je cherche en console: ', this.typesEtablissementPieChart)

      await this.action_getStatisticsExploitationEtablissements({
        data: { tables },
      })
        .then(res => {
          // console.log('ce que je reçois du backend: ', res)
          // return
          const { originalResponse } = res
          // data.resources.total
          // console.log('originalResponse.data.resources.total: ', originalResponse.data.resources.total)
          this.finalData = originalResponse.data.resources.total
          // return

          // if (resources && Array.isArray(resources)) {
          //   // departements
          //   const statsData = resources.find(
          //     e => e[fieldKey] === elemId,
          //   )
          //   const statsDataValues = this.processEtsCount(statsData)

          //   const labels = statsDataValues.map(e => Object.keys(e)[0])
          //   const series = statsDataValues.map(e => Object.values(e)[0])
          //   this.chartData.listData = labels.map((e, index) => ({
          //     icon: 'CircleIcon',
          //     iconColor: 'text-danger',
          //     text: e,
          //     result: series[index],
          //   }))

          //   this.femmeNationauxPie.chartOptions.labels = labels
          //   this.femmeNationauxPie.series = series

          // this.femmeEtrangersPie.chartOptions.labels = labels
          // this.femmeEtrangersPie.series = series

          // this.hommeNationauxPie.chartOptions.labels = labels
          // this.hommeNationauxPie.series = series

          // this.hommeEtrangersPie.chartOptions.labels = labels
          // this.hommeEtrangersPie.series = series
          // }
          this.isLoading = false

          // console.log('stats types ets', res)
        })
        .catch(error => {
          this.isLoading = false
          // console.log('reponse error: ', error)

          // console.log(error)
        })
    },
    // processEtsCount(resources) {
    //   const { resourcesCount, valuesObjects } = resources || {}
    //   let result = []
    //   if (
    //     Array.isArray(resourcesCount) && Array.isArray(valuesObjects) && resourcesCount.length === valuesObjects.length
    //   ) {
    //     // eslint-disable-next-line comma-spacing
    //     result = valuesObjects.map((e, i) => ({ [e.nom]: resourcesCount[i] }))
    //   }
    //   return result
    // },
  },
  // created() {
  //   this.$http.get('/card/card-analytics/customers').then(res => { this.chartData = res.data })
  // },
}
</script>
