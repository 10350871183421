<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Nombre d'établissements par service </b-card-title>
      <!-- <b-dropdown
        text="Choisir une periode"
        variant="transparent"
        class="chart-dropdown"
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item v-for="day in chartData.lastDays" :key="day">
          {{ day }}
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>
      <!-- apex chart -->
      <vue-apex-charts
        type="pie"
        height="325"
        class="mt-2 mb-1"
        :options="customersPie.chartOptions"
        :series="customersPie.series"
      />

      <!-- list group -->
      <div class="pt-25">
        <div
          v-for="(data, index) in chartData.listData"
          :key="index"
          class="d-flex justify-content-between"
          :class="
            index === Object.keys(chartData.listData).length - 1 ? '' : 'mb-1'
          "
        >
          <div class="series-info">
            <feather-icon
              :icon="data.icon"
              size="16"
              class="mr-50"
              :class="data.iconColor"
            />
            <span class="font-weight-bolder">{{ data.text }}</span>
          </div>
          <span>{{ data.result }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
  },
  data() {
    return {
      chartData: {},
      customersPie: {
        series: [60, 258, 149,444],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ["Billeterie", "Voyage", "Croisière", "Autres"],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: [
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.danger,
            $themeColors.secondary,
          ],
        },
      },
    };
  },
  created() {
    this.chartData = {
      lastDays: ["28 Derniers jours", "Mois dernier", "Annee Dernière"],
      listData: [
        {
          icon: "CircleIcon",
          iconColor: "text-primary",
          text: "Billeterie",
          result: 890,
        },
        {
          icon: "CircleIcon",
          iconColor: "text-warning",
          text: "Voyage",
          result: 258,
        },
        {
          icon: "CircleIcon",
          iconColor: "text-danger",
          text: "Croisière",
          result: 149,
        },
        {
          icon: "CircleIcon",
          iconColor: "text-danger",
          text: "Autres",
          result: 149,
        },
      ],
    };
    this.customersPie.series = this.chartData.listData.map(e=>e.result)
  },
};
</script>
