<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <!-- <b-col lg="6">
        <StatsEtablissementParService />
      </b-col> -->

      <b-col lg="12">
        <StatsExploitationEtablissement
          :etablissement-id="etablissementId"
          :title="StatsExploitationEtablissementTitle"
        />
      </b-col>
      <b-col
        v-if="!isEtablissementUser"
        lg="12"
      >
        <StatsEtablissementParType />
      </b-col>
      <b-col
        v-if="!isEtablissementUser"
        lg="12"
      >
        <StatsEtablissementParLocalisation />
      </b-col>

      <!-- Statistiques pour le nombre d'enmployés salariés -->
      <b-col
        v-if="!isEtablissementUser"
        lg="12"
      >
        <StatsNombreEmployesSalaries />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BCardText,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import utilsService from '@/services/utils/utils.service'
import StatsEtablissementParType from './StatsEtablissementParType.vue'
import StatsEtablissementParLocalisation from './StatsEtablissementParLocalisation.vue'
import StatsEtablissementParService from './StatsEtablissementParService.vue'
import StatsNombreEmployesSalaries from './employes/StatsNombreEmployesSalaries.vue'

const StatsExploitationEtablissement = () => import('@/views/statistics/etablissements/exploitation-etablissement/StatsExploitationEtablissement.vue')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BCardText,
    StatsEtablissementParType,
    StatsEtablissementParLocalisation,
    StatsEtablissementParService,
    StatsExploitationEtablissement,
    StatsNombreEmployesSalaries,
},
  setup() {
    const {
      isAdminEtablissement,
      isAgentEtablissement,
    } = utilsService.currentUserUtils()
    const isEtablissementUser = isAdminEtablissement || isAgentEtablissement
    return { isAdminEtablissement, isAgentEtablissement, isEtablissementUser }
  },
  data() {
    return {
    }
  },
  computed: {
    etablissementId() {
      return utilsService.etablissementId
    },
    StatsExploitationEtablissementTitle() {
      return this.isEtablissementUser
        ? "Statistiques sur l'exploitation de mon établissement"
        : "Statistiques sur l'exploitation des établissements"
    },
  },
  created() {
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
