<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Nombre d'établissements par type </b-card-title>
      <b-dropdown
        text="Choisir une periode"
        variant="transparent"
        class="chart-dropdown"
        right
        no-caret
        toggle-class="p-0 mb-25"
        size="sm"
      >
        <b-dropdown-item v-for="day in chartData.lastDays" :key="day">
          {{ day }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body class="d-flex flex-column justify-content-center">
      <content-loader
        v-if="isLoading && false"
        view-box="0 0 400 460"
        :speed="2"
        primary-color="#f3f3f3"
        secondary-color="#ecebeb"
      >
        <circle cx="29" cy="30" r="17" />
        <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
        <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
        <rect x="-7" y="60" rx="2" ry="2" width="408" height="132" />
      </content-loader>
      <div
        v-if="isLoading"
        class="d-flex align-items-center justify-content-center"
      >
        <b-spinner label="Spinning" />
      </div>
      <b-row>
        <b-col lg="6">
          <!-- list group -->
          <div v-if="!isLoading" class="pt-25">
            <div
              v-for="(data, index) in chartData.listData"
              :key="index"
              class="d-flex justify-content-between"
              :class="
                index === Object.keys(chartData.listData).length - 1
                  ? ''
                  : 'mb-1'
              "
            >
              <div class="series-info">
                <feather-icon
                  :icon="data.icon"
                  size="16"
                  class="mr-50"
                  :class="data.iconColor"
                />
                <span class="font-weight-bolder">{{ data.text }}</span>
              </div>
              <b-badge variant="light-dark">
                <span>{{ data.result }}</span>
              </b-badge>
            </div>
          </div></b-col
        >
        <b-col lg="6">
          <!-- apex chart -->
          <vue-apex-charts
            v-if="!isLoading"
            type="pie"
            height="325"
            class="mt-2 mb-1"
            :options="typesEtablissementPieChart.chartOptions"
            :series="typesEtablissementPieChart.series"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { mapActions, mapState } from "vuex";
import { ContentLoader } from "vue-content-loader";

// store modules and vuex utilities
import statisticsStoreModule from "@/store/statistics";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

const chartLabels = [];
const chartData = {
  lastDays: ["28 Derniers jours", "Mois dernier", "Annee Dernière"],
  listData: [
    {
      icon: "CircleIcon",
      iconColor: "text-primary",
      text: "Agences de voyage",
      result: 0,
    },
    {
      icon: "CircleIcon",
      iconColor: "text-warning",
      text: "Tour operator",
      result: 0,
    },
    {
      icon: "CircleIcon",
      iconColor: "text-danger",
      text: "Agence réceptive",
      result: 0,
    },
  ],
};
export default {
    setup() {
    const requiredStoreModules = [
      { path: "statistics", module: statisticsStoreModule },
    ];

    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  }, 
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
    ContentLoader,
    BSpinner,
    BBadge,
  },
  data() {
    return {
      chartLabels,
      chartData,
      typesEtablissementPieChart: {
        series: [0, 0, 0],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: chartLabels,
          dataLabels: {
            enabled: true,
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: [
            $themeColors.primary,
            $themeColors.warning,
            $themeColors.danger,
          ],
        },
      },
      isLoading: true,
    };
  },
  async mounted() {
    // this.isLoading = false
    await this.getStatistics();
  },
  methods: {
    ...mapActions("statistics", {
      action_getStatisticsAgencesVoyageToursOperators:
        "getStatisticsAgencesVoyageToursOperators",
    }),
    async getStatistics() {
      this.isLoading = true;
      const fieldKey = "field";
      const typeEtablissementQueryId = "typeEtablissement";
      const queryParams = {
        fields: ["_id", typeEtablissementQueryId],
      };

      // console.log('ce que je voulais: ', this.typesEtablissementPieChart)

      await this.action_getStatisticsAgencesVoyageToursOperators({
        params: queryParams,
      })
        .then((res) => {
          const { data } = res;
          const { resources } = data;

          if (resources && Array.isArray(resources)) {
            // departements
            const statsData = resources.find(
              (e) => e[fieldKey] === typeEtablissementQueryId
            );
            const statsDataValues = this.processEtsCount(statsData);
            //             statsDataValues.forEach((element, statsIndex) => {
            //               const itemKey = Object.keys(element)[0]
            //               const itemValue = Object.values(element)[0]
            //               // const foundIndex = labels.findIndex(e => e && e.toLowerCase() === (itemKey && itemKey.toLowerCase()))
            //               // this.chartLabels.push(itemKey)
            // console.log(itemKey);
            //               // console.log(itemKey, statsIndex, labels)
            //               // if (foundIndex !== -1) {
            //                  this.chartLabels[statsIndex]=itemKey
            //                 // this.typesEtablissementPieChart.series[statsIndex] = itemValue
            //                 // this.chartData.listData[statsIndex].result = itemValue
            //               // }
            //             })

            const labels = statsDataValues.map((e) => Object.keys(e)[0]);
            const series = statsDataValues.map((e) => Object.values(e)[0]);
            this.chartData.listData = labels.map((e, index) => {
              return {
                icon: "CircleIcon",
                iconColor: "text-danger",
                text: e,
                result: series[index],
              };
            });

            this.typesEtablissementPieChart.chartOptions.labels = labels;
            this.typesEtablissementPieChart.series = series;
          }
          this.isLoading = false;

          // console.log('stats types ets', res)
        })
        .catch((error) => {
          this.isLoading = false;

          // console.log(error)
        });
    },
    processEtsCount(resources) {
      const { resourcesCount, valuesObjects } = resources || {};
      let result = [];
      if (
        Array.isArray(resourcesCount) &&
        Array.isArray(valuesObjects) &&
        resourcesCount.length === valuesObjects.length
      ) {
        // eslint-disable-next-line comma-spacing
        result = valuesObjects.map((e, i) => ({ [e.nom]: resourcesCount[i] }));
      }
      return result;
    },
  },
  created() {},
};
</script>
